@import "../../../index.scss";

.surveyoverview {
  &__section {
    color: $black;
  }

  h2 {
    color: $black;
  }

  p {
    color: $grey;
  }

  &__content {
    flex-flow: row wrap;
  }

  &__circle {
    min-width : 45%;
  }

  &__sectionDescription {
    min-width: 47%;
  }

  &__sectionText {
    color: $black;
    font-size: 18px;
    max-width: 160px;
  }

  &__sectionText__NumberOfQuestions {
    color: $grey;
    font-size: 14px;
    max-width: 200px;
    margin: auto;
  }

  &__heading {
    font-size: 26px;
  }

  &__image {
    height: 130px;
  }

  &__item:last-child {
    @media (min-width: 1200px) {
			padding-right: 0;
		}
  }
  
}
