@import "../../../index.scss";

.infoliststeps {
  &__step {
    color: $black;
  }

  h2 {
    color: $black;
  }

  p {
    color: $grey;
  }

  &__stepText {
    color: $grey;
    font-size: 16px;
    max-width: 200px;
    margin: auto;
  }

  &__heading {
    font-size: 26px;
  }

  &__image {
    img {
      width: 151px;
      @media (max-width: 640px) {
        width: 126px;
        height: 124px;
      }
    }
  }
}

.ie-grid {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
