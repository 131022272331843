@import "../../../index.scss";
.info-bullit-list-wrapper {
  background-color: #F3F3F3;
  .infolistbullets {
    h2 {
      color: $black;
    }

    p {
      color: $grey;
    }

    &__heading {
      font-size: 26px;
    }

    &__bulletText {
      color: $grey;
      font-size: 16px;
      max-width: 200px;
      margin: auto;
    }
  }
}
