@import '../../../index.scss';
.cta-div-opt-in,
.cta-div-opt-in .cta-div {
  background-color: $light-grey !important;
}

.cta {
    background-color: $red !important;
    color: $white;
    min-width: 250px;

    &:hover {
      background-color: $dark-red !important;
    }


    &-div {
        background-color: $light-grey;
        min-height: 140px;

        p {
            color: $black; 
        }

        .checkbox-container {
            display: block;
            position: relative;
            padding-left: 40px;
            margin-bottom: 30px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-top: 3px;

            @media (max-width: 320px) {
                margin-bottom: 3px;
            }
          }
          
          .checkbox-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
            
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            width: 30px;
            background-color: $white;
            border-radius: 5px;
            border: 2px solid $black;

            &:hover {
                border: 2px solid $red;
            }
          }
          
          .checkbox-container:hover input ~ .checkmark {
            background-color: $white;
          }
           
          .checkbox-container input:checked ~ .checkmark {
            background-color: $black;
          }
           
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
           
          .checkbox-container input:checked ~ .checkmark:after {
            display: block;
          }
          
          .checkbox-container .checkmark:after {
            left: 9px;
            top: 2px;
            width: 9px;
            height: 18px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
    }

}

.optincta-modal {

  &__title {
    font-size: revert;
  }
  &__close-icon {
    fill: $black;
  }
}

.react-responsive-modal-modal {
  max-width: 600px !important;
  position:absolute !important;
  top: 0 !important; bottom: 0 !important; left: 0 !important; right: 0 !important;
  margin: auto !important;
  height: 90%; /*requires explicit height*/
  padding-top:0px !important;
  border-top: 33px solid transparent !important;
  border-bottom: 33px solid transparent !important;

  @media (max-width: 400px) {
    max-width: 336px !important;
  }

  @media (max-width: 320px) {
    max-width: 280px !important;
  }
}

@keyframes cssAnimation {
  to   { opacity: 1; }
}

.react-responsive-modal-modal h2 {
  padding: 0px !important;
}

.react-responsive-modal-modal {
  .react-responsive-modal-closeButton {
    animation: cssAnimation 0s .5s forwards;
    opacity: 0;
    position: fixed !important;
    top: 55px;
    right: 40px;
    background-color: $white;
    padding: 1px;
    border: 1px solid #969595;
    border-radius: 50px;
    outline: none;

    @media (max-width: 768px) {
      right: 30px;
    }
  
    &:focus,
      &:active {
        outline: none;
        border: none;
      }
  }
  p {
    margin-top:30px;
  }
  p strong {
    display: block;
  }
  ul {
    color: #575B60;
    margin-left:10px;
    list-style-type: circle;
    li {
      margin: 10px;
      list-style-type: disc;
    }
    li:first-child {
      margin-top: 20px;
    }
    li:last-child {
      margin-bottom:30px;
    }
  }
  ol {
    color: #575B60;
    margin-left:10px;
    list-style: lower-alpha;
    li {
      margin: 10px;
    }
    li:first-child {
      margin-top: 20px;
    }
    li:last-child {
      margin-bottom:30px;
    }
  }
  a {
    text-decoration: underline !important;
  }

}