@import "../../../index.scss";

.smilyContainer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
 }
 .text-underline {
  color: white;
  text-decoration-line:underline;
 }
 .text-color {
  color: white;
 }
 .mt-10px{
   margin-top: 10px;
 }
 .mb-10px{
  margin-bottom: 10px;
}
.ml-42-percent{
margin-left: 42%;
}

.text-center{
  text-align: center;
}

.vl {
  margin: auto;
  width: 0%;
  border-left: 2px dashed $light-red;
  padding: 0px;
  height:30px;
}

.line{
  display: flex;
  flex-direction: column;
}

.arrow-down{
  color: $light-red;
  font-size: 12px;
  font-family: sans-serif;
  @media screen and (max-width: 480px) {
    margin-right: 1px;
  }
}

.feedback-card {
  width: 100%;
  background: $light-grey;
  margin-top: -1px;
  padding: 40px;
  justify-content: center;
  &-wrapper {
    min-height: 130px;
    max-width: 420px;
  }
}

.feedback-container {
  background-color:$white;
  padding-top: 30px;
}

.pr-15px{
  padding-right: 15px;
}

.font-size-10px{
  font-size: 11px;
}

.mr-left-8px{
  margin-left: -8px;
}

.pd-10px{
  padding: 10px;
}

.btn-background{
  background-color: $red !important;
}

.successIcon {
  display: flex;
  justify-content: center;
}

.pb-10px{
  padding-bottom: 10px;
}

.mt-8px{
  margin-top: 8px;
}

.default-color{
  background-color: $lighter-blue;
}

.mt-15px{
  margin-top: 15px;
}

.mt-12px{
  margin-top: 12px;
}

.p-5px{
  padding:5px;
}

.pt-2px{
  padding-top: 2px;
}

.feedback-card-white {
    width: 100%;
    margin-top: -1px;
    padding: 8px;
    min-height: 130px;
    max-width: 561px;
    margin-left: auto;
    margin-right: auto;
  }

  .pointer{
    cursor: pointer;
  }
  .feedback-text {
    color: $grey;
  }
  
  .feedback-container {
    margin-top: 32px;
    padding-bottom: 10px;
  }
  .feedback-useful-links .usefulLink img {
    max-height: 50px;
    max-width: 170px;
    visibility:visible;
    margin:10px;
    display: inline;
  }