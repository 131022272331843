@import "../../../index.scss";

footer {
  background-color: $light-grey;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100%;

  a {
    color: $grey;
  }

  ul {
    li {
      border-color: $grey;
    }
  }
  .logo {
    padding-bottom:5px;
    
    p {
      margin: auto;
      vertical-align:middle;
    }
    span {
      margin: auto;
      vertical-align: middle;
    }
    .logoImage {
      vertical-align:middle;
      display:inline-block;
    }
  }
  .otherLinks {
    display: block;
    margin-top: 15px;
  }
  .otherLink {
    margin: 0px 15px
  }
}
