@import "../../../index.scss";

.infopodwrapper{
padding: 18px;
color: $black;
border-radius: 10px;
background-color: $white; //#00b3ca;
margin-bottom: 60px;
    p {
        color: $red;
        font-size:16px;
        font-weight:500;
        @media (max-width:900px) {
            font-size:14px;
        } 
        @media (max-width:400px) {
            font-size:13px;
        } 
    }
    strong {
        font-size:22px;
        @media (max-width:900px) {
            font-size:17px;
        } 
        @media (max-width:400px) {
            font-size:16px;
        } 
    }
}

.flex{
display: flex;
}
.width-40{
width: 40%;
}

.width-60{
width: 60%;
}

.mt-25px{
margin-top: 25px;
}

.font-25px{
    font-size: 25px;
}

.mb-10px{
    margin-bottom: 10px;
}

.mt-15px{
    margin-top: 15px;
}

.infopod-image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
}

.infopod-image-container {
    padding: 0px 40px;
    @media (min-width: 1400px) {
        padding: 0px 85px;
    }
    @media (min-width: 1300px) {
        padding: 0px 70px;
    }
    @media (max-width: 1200px) {
        padding: 0px 50px;
    }
    @media (max-width: 1100px) {
        padding: 0px 30px;
    }
    @media (max-width: 1000px) {
        padding: 0px 10px;
    }
    @media (max-width: 850px) {
        padding: 0px 5px;
    }
    @media (max-width: 800px) {
        padding: 0px;
    }
}