@import "../../../index.scss";

.hero-clubbed h2,
.hero-clubbed p {
  color: $black;
}

h2 {
  color: $black;
}

p {
  color: $grey;
}

.page-description {
  background-color: $light-grey;
  @media (min-width: 640px) {
    max-height: 545px;
  }

  &-wrapper {
   max-width: 520px;
   
   @media (max-width:1000px) {
     max-width: 100%;
     margin-top:20px;
   }
  }
}

.dflex{
  display: flex;
  justify-content: space-between;
  }
  
  .infopodContainer{
  margin-left: 20px;
  @media (max-width: 1000px) {
  margin-top:20px;
  }
  @media (max-width: 800px) {
  margin-top: 50px;
  }
  }

.usefulLinkContainer {
  display: block;
  text-align: center;
  background-color: #f7f7f7;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.usefulLinkContainer span {
  padding-top:10px;
  font-weight: bold;
  display: block;
}
.usefulLinkContainer .usefulLink a {
  text-align: left;
}
.usefulLinkContainer .usefulLink img {
  max-height: 50px;
  max-width: 170px;
  visibility:visible;
  padding:10px;
  display: inline;
}