@import '../../../index.scss';
.infolistparagaphmedia {

  margin: 0px auto;
  background-color: $white; //$dark-gray;

  h2 {
    color: $black;
    margin-left: -17px;

    @media (min-width: 1024px) {
      margin-left: -6px;
    }
  }

  p {
    color: $grey;
  }

  &__heading {
    font-size: 26px;
  }
  ul {
    list-style:circle;
  } 

  .paragraph-standard {
    width: 100%;

    @media (min-width: 2200px) {
      padding-right: 15px;

      &:last-child {
        padding-right: 0;
      }
    }

    &__heading {
      color: $black;
    }

  }

  
}

.ie-grid {
    display: flex;
    width: 100%;
    justify-content: space-around;
}