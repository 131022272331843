@import '../../../index.scss';

.cta {
    background-color: $red !important;
    color: $white;
    min-width: 250px;

    &:hover {
        background-color: $dark-red !important;
    }

    &-div {
        background-color: $white !important;
        min-height: 135px;

        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}

.results .cta-div {
    padding: 40px;
    background-color: $light-grey !important;
}