@import "../../../index.scss";

.page-issue {
  min-height: 80vh;
  background-color: $light-grey;

  .cta-div {
    background-color: transparent;
    min-height: 120px;

    a {
      background-color: $red !important;
      min-width: 10rem !important;
    }
  }

}
