@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
$blue: #0a366b;
$lighter-blue: #f3f7f9;
$dark-gray: #e7ebf1;
$light-grey: #f7f7f7;
$grey: #575b60;
$light-blue: #849ab5;
$white: #ffffff;
$black: #000000;
$turquoise: #00b3ca;
$yellow: #f7c13b;
$dark-yellow: #f1b41c;
$orange: #ee8e30;
$pink: #f52f5b;
$green: #00d4a7;
$focus: #880828;
$red: #C50F3C;
$dark-red: #880828;
$light-red: #E699AB;

html,
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
  html {
    scroll-behavior: smooth;
  }
}

.wrapper {
  background: $dark-gray;
  width: 100%;
  min-height: 85vh;
}

.main {
  width: 100%;
  max-width: 1400px
  //min-height: 100vh;
}

.container {
  width: 100%;
  max-width: 1536px;
  position: relative;
  padding-bottom: 0px;
}

a {
  color: $black;
}

div,
p {
  clear: both;
  position: relative;
}

a:focus {
  outline-width: 1px;
  outline-style: solid;
  outline-color: $focus;
}

button:focus {
  outline-width: 1px !important;
  outline-style: solid !important;
  outline-color: $focus !important;
}


.spotlight:focus{
  outline-width: 0px !important;
  outline-style: none !important;
}

div:focus{
  outline-width: 0px !important;
  outline-style: none !important;
}