@import '../../../index.scss';

.survey-page {
    background-color: $white;
    border: 1px solid $white;
}

.survey-wrapper {

    .question-container {
        &:first-child {
            padding-top: 0;
        }

        &.grouped-question {
            background-color: $dark-gray;
        }
    }

    .section-image {
        img {
            width: 200px;
        }
    }
}