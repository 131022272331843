@import "../index.scss";

$AnswerHover: #F0F0F0;

.survey-wrapper {
  background: $light-grey;
  width: 100%;
}

.card {
  width: 100%;
  background: $white;
  border: 1px solid $white;
  padding: 30px;
  border-radius: 10px;
  min-height: 200px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.24);
  max-width: 561px;
  margin-left: auto;
  margin-right: auto;

  &.in-valid {
    border: 3px solid $light-red;
  }

  &__focus {
    border: 1px solid $focus;
  }

  &__numbers {
    color: $grey;
    margin-bottom: 15px;
    display: block;
  }

  &__group {
    &__numbers {
      color: $grey;
      display: block;
    }
  }

  .grouped-question-title + .card__group__numbers {
    padding-top: 25px;
  }
  
}

.mutliple-choice-field {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;

  &__label {
    background: $light-grey;
    display: block;
    padding: 15px 25px;
    border-radius: 10px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: $grey;
    cursor: pointer;
  }
  &__radio {
    opacity: 0;
    position: fixed;
    width: 0;
    &:checked + .mutliple-choice-field__label {
      background: $dark-red;
      color: $white;
    }
    &:hover + &:not(:checked) + .mutliple-choice-field__label {
      background: $AnswerHover;
    }
  }
  &:hover {
    background: #000000;
  }
}

.rating-container {
  &.has-changed {
    input[type=range]::-webkit-slider-thumb {
      background: $red;
    }

    input[type=range]::-moz-range-thumb {
      background: $red;
    }

    input[type=range]::-ms-thumb {
      background: $red;
    }

  }

  .rating-numbers-menu,  .rating-text-menu {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      color: $grey;
  }

  .rating-numbers-menu {
    padding-left: 10px;
    padding-right: 6px;
  }

  .rating-numbers-list {
    position: relative;

    li:nth-child(2) {
      background: $red;
    }
  }

  .rating-numbers-list, .rating-text-list {
    flex: 0 1 auto;
    list-style-type: none;
    cursor: pointer;
  }

  .rating-numbers-list::before {
    content: "\2212";
    transform: rotate(90deg);
    font-size: 1rem;
    position: absolute;
    top: -19px;
    color: $dark-gray;
    z-index: 1;
  }

  .rating-text-list {
    width: min-content;
  }

  input[type=range]::-webkit-slider-container {
    min-block-size: 15px;
  }
  input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: $light-grey;
    border-radius: 1.3px;
    z-index: 2;
  }
  input[type=range]::-webkit-slider-thumb {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $red;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: $light-grey;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: $light-grey;
    border-radius: 1.3px;
    z-index: 2;
  }
  input[type=range]::-moz-range-thumb {
    border: 1px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $red;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: $light-grey;
    border-color: transparent;
    color: transparent;
    z-index: 2;
  }
  input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border-radius: 2.6px;
  }
  input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border-radius: 2.6px;
  }
  input[type=range]::-ms-thumb {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $red;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
  }

}

.yes-no-field {
  margin-bottom: 15px;
  margin-right: 20px;
  display: flex;
  width: 100%;
  justify-content: center;

  &__span {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 24px;
    svg {
      width: 100%;
      text-align: center;
    }
  }
  &__li {
    margin-right: 40px;
    text-align: center;
    width: 105px;
    &:last-child {
      margin-right: 0px;
    }
  }
  &__label {
    background: $light-grey;
    display: block;
    padding: 25px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    color: $grey;
    cursor: pointer;
  }
  &__radio {
    opacity: 0;
    position: fixed;
    width: 0;
    &:checked + .yes-no-field__label {
      background: $red;
      color: $white;
      &:hover {
        background: $dark-red;
        color: $white;
      }
    }
    &:hover + .yes-no-field__label {
      background: $AnswerHover;
    }
  }
}

.next-btn-wrapper {
  width: 100%;
  background-color: $white;
}

.btn-primary {
  background-color: $red;
  border-color: $red;
  color: $white;

  &:hover {
    background-color: $dark-red;
  }
}

.radio {
  opacity: 0;
  position: fixed;
  width: 0;
}
.mutliple-choice-fields {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
}
.label-unselected {
  background: $light-grey;
  display: block;
  padding: 15px 25px;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: $grey;
  cursor: pointer;
  span {
    display: none;
  }
}
.label-selected {
  background: $red;
  display: block;
  padding: 15px 25px;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  span {
    display: inline-block;
    color: #ffffff;
    font-size: 28px;
    height: 0;
  }
}

.error-invalid {
  display: flex;
  justify-content: center;
  color: $red;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 25px;
  }
}

.yesNoField-Text {
  width: 100%;
  display: flex;
  .yesNoField-wrapper {
    width: 100%;
    .question-title {
      color: $black;
    }
    .field-wrapper {
      .field {
        background-color: $light-grey;
        border: 1px solid $light-grey;
        border-radius: 5px;
      }
    }
  }
}

.yes-no-wrapper {
  width: 100%;
  .question-title {
    color: $black;
  }
  .field-wrapper {
    .field {
      // background-color: $light-grey;
      // border: 1px solid $light-grey;
      border-radius: 5px;
      outline: none;
    }
  }

  .item-wrapper {
    // background-color: $light-grey;
    // border: 1px solid $light-grey;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }

  .btn-wrapper {
    button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: 1px solid #c9d2dd;
      border-radius: 5px;
      color: $red;
      outline: none;
    }
  }
}

.section {
  &__title {
    color: $black;
    text-transform: uppercase;
  }
}

.height-20px{
  height: 20px;
  margin-bottom: 2px;
}

.btn-pad-25px{
  padding: 20px;
  cursor: pointer;
  }
  
  .p-0px{
  padding: 0px;
  }
  
  .mt-4px{
  margin-top: 4px;
  }
  
  .ml-25px{
  margin-left: 25px;
  }
  
  .mr-15px{
  margin-right: -15px;
  }
  
  .item-ans {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  }
  
  .item-qus {
  border: 1px solid #c9d2dd;
  border-radius: 5px;
  color: $black;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  }
  
  .mutlitext-label{
  font-weight: 600;
  color: $black;
  }

  .pb-15px{
    padding-bottom: 10px;
  }
  .font-color{
    color: $grey
  }

  .selected_label_color{
    background-color: $dark-red;
  }

  .unselected_label_color{
     background-color: #F0F0F0;
  }

  textarea:focus,
  textarea:focus-visible {
      border: 1px solid $focus !important;
  }