@import "../../../index.scss";
.hero-clubbed .header {
  background-color: transparent !important;
}
.hero-clubbed h2,
.hero-clubbed p {
  padding: 30px 18px;
}

.hero-clubbed .page-description-wrapper {
    border-radius: 10px;
    background-color: #ffffff80;
}
.results .hero-clubbed .page-description-wrapper {
  background-color: transparent;
}

.hero {
  //background-color: $dark-gray;
  padding-bottom: 50px;

  img {
    max-height: 488px;
    margin-left: auto;
    
    @media (min-width: 1200px) {
      width: 590px;
    }

    @media (min-width: 1024px)
    {
      visibility: hidden;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }

  // picture {
  //   margin-left: auto;
  // }

  // @media (min-width: 640px) {
  //   max-height: 545px;
  // }

  @media (min-width: 1200px) {
    .hero__content {
      min-width: 500px;
    }
  }
  

  @media (min-width: 2200px) {
    .hero__content {
      width: 100%;
      display: flex;
      padding-bottom: 0px;
      align-items: center;
    }
    .hero {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .container {
    @media (min-width: 770px) {
      z-index: 3;
      padding-top: 20px;
    }
    
    @media (min-width: 1000px) {
      //margin-top: -40px;
      z-index: 3;
    }
  }
}