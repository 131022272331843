@import "../../../index.scss";

 .breadcrumbContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  
  @media screen and (min-width: 480px) {
    margin-left: 48px;
  }
  @media screen and (max-width: 480px) {
    margin-left: 10px;
  }
 }

 .breadcrumb {
  display: flex;
  align-self: center;
  margin-top: 40px;
  height: 60px;
}

.breadcrumbItem {
  margin-right: 48px;
  @media screen and (max-width: 480px) {
    margin-right: 15px;
  }
}

.line-grey{
  border-bottom: 2px solid #C9D2DD;
  position: relative;
  top: 28px;
  left: 52px;
  width: 60px;
  @media screen and (max-width: 480px) {
    width: 21px;
  }
}

.line-blue {
  border-bottom: 2px solid $grey;
  position: relative;
  top: 28px;
  left: 52px;
  width: 60px;
  @media screen and (max-width: 480px) {
    width: 21px;
  }
}

.circle {
  position: relative;
  width: 50px;
  height: 50px;
  border: 2px solid #C9D2DD;
  border-radius: 50%;
  margin: 5%;
  padding-right: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.circle.fill {
  background-color: $red;
  border-color: $red;
  color: white;
}

.fill:hover {
  background-color: $dark-red;
  border-color: $dark-red;
}

.circleSmall {
  position: absolute;
  top: 0%;
  left: -7%;
  width: 40%;
  height: 40%;
  background-color: $red;
  border-radius: 50%;
}

.tick {
  position: relative;
  top: -4px;
  color: white;
  height: 21px;
  width: 10px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
  font-family: system-ui;
}

.circleText  {
   font-size: 18px;
   padding-left: 18px;
}

.circleText.circleText-gray {
  color:#C9D2DD;
}

.marginleft-3{
 margin-left: 2px !important;
}

.border-blue{
  border-color: $grey;
  color: $black;
}

.text-blue{
  color: $red !important;
}

.text-blue{
  color: white !important;
}

.completed:hover{
   background-color: white;
   color: $red;
 }

.borderSmall{
  border: 1px solid;
  border-color: white;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: $dark-red;
  color: white;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent $dark-red transparent transparent;
}

.colorWhite{
  color: white;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.marginsToolTip{
  margin-top:-40px;
  font-size: 12px;
}

.padding-20px{
  padding: 20px;
}

.padding-left-17px{
  padding-left: 17px;
}

.width-1px{
  width: 55px;
}